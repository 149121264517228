export const kelvinTable = {
  1000: { r: 255, g: 56, b: 0 },
  1100: { r: 255, g: 71, b: 0 },
  1200: { r: 255, g: 83, b: 0 },
  1300: { r: 255, g: 93, b: 0 },
  1400: { r: 255, g: 101, b: 0 },
  1500: { r: 255, g: 109, b: 0 },
  1600: { r: 255, g: 115, b: 0 },
  1700: { r: 255, g: 121, b: 0 },
  1800: { r: 255, g: 126, b: 0 },
  1900: { r: 255, g: 131, b: 0 },
  2000: { r: 255, g: 138, b: 18 },
  2100: { r: 255, g: 142, b: 33 },
  2200: { r: 255, g: 147, b: 44 },
  2300: { r: 255, g: 152, b: 54 },
  2400: { r: 255, g: 157, b: 63 },
  2500: { r: 255, g: 161, b: 72 },
  2600: { r: 255, g: 165, b: 79 },
  2700: { r: 255, g: 169, b: 87 },
  2800: { r: 255, g: 173, b: 94 },
  2900: { r: 255, g: 177, b: 101 },
  3000: { r: 255, g: 180, b: 107 },
  3100: { r: 255, g: 184, b: 114 },
  3200: { r: 255, g: 187, b: 120 },
  3300: { r: 255, g: 190, b: 126 },
  3400: { r: 255, g: 193, b: 132 },
  3500: { r: 255, g: 196, b: 137 },
  3600: { r: 255, g: 199, b: 143 },
  3700: { r: 255, g: 201, b: 148 },
  3800: { r: 255, g: 204, b: 153 },
  3900: { r: 255, g: 206, b: 159 },
  4000: { r: 255, g: 209, b: 163 },
  4100: { r: 255, g: 211, b: 168 },
  4200: { r: 255, g: 213, b: 173 },
  4300: { r: 255, g: 215, b: 177 },
  4400: { r: 255, g: 217, b: 182 },
  4500: { r: 255, g: 219, b: 186 },
  4600: { r: 255, g: 221, b: 190 },
  4700: { r: 255, g: 223, b: 194 },
  4800: { r: 255, g: 225, b: 198 },
  4900: { r: 255, g: 227, b: 202 },
  5000: { r: 255, g: 228, b: 206 },
  5100: { r: 255, g: 230, b: 210 },
  5200: { r: 255, g: 232, b: 213 },
  5300: { r: 255, g: 233, b: 217 },
  5400: { r: 255, g: 235, b: 220 },
  5500: { r: 255, g: 236, b: 224 },
  5600: { r: 255, g: 238, b: 227 },
  5700: { r: 255, g: 239, b: 230 },
  5800: { r: 255, g: 240, b: 233 },
  5900: { r: 255, g: 242, b: 236 },
  6000: { r: 255, g: 243, b: 239 },
  6100: { r: 255, g: 244, b: 242 },
  6200: { r: 255, g: 245, b: 245 },
  6300: { r: 255, g: 246, b: 247 },
  6400: { r: 255, g: 248, b: 251 },
  6500: { r: 255, g: 249, b: 253 },
  6600: { r: 254, g: 249, b: 255 },
  6700: { r: 252, g: 247, b: 255 },
  6800: { r: 249, g: 246, b: 255 },
  6900: { r: 247, g: 245, b: 255 },
  7000: { r: 245, g: 243, b: 255 },
  7100: { r: 243, g: 242, b: 255 },
  7200: { r: 240, g: 241, b: 255 },
  7300: { r: 239, g: 240, b: 255 },
  7400: { r: 237, g: 239, b: 255 },
  7500: { r: 235, g: 238, b: 255 },
  7600: { r: 233, g: 237, b: 255 },
  7700: { r: 231, g: 236, b: 255 },
  7800: { r: 230, g: 235, b: 255 },
  7900: { r: 228, g: 234, b: 255 },
  8000: { r: 227, g: 233, b: 255 },
  8100: { r: 225, g: 232, b: 255 },
  8200: { r: 224, g: 231, b: 255 },
  8300: { r: 222, g: 230, b: 255 },
  8400: { r: 221, g: 230, b: 255 },
  8500: { r: 220, g: 229, b: 255 },
  8600: { r: 218, g: 229, b: 255 },
  8700: { r: 217, g: 227, b: 255 },
  8800: { r: 216, g: 227, b: 255 },
  8900: { r: 215, g: 226, b: 255 },
  9000: { r: 214, g: 225, b: 255 },
  9100: { r: 212, g: 225, b: 255 },
  9200: { r: 211, g: 224, b: 255 },
  9300: { r: 210, g: 223, b: 255 },
  9400: { r: 209, g: 223, b: 255 },
  9500: { r: 208, g: 222, b: 255 },
  9600: { r: 207, g: 221, b: 255 },
  9700: { r: 207, g: 221, b: 255 },
  9800: { r: 206, g: 220, b: 255 },
  9900: { r: 205, g: 220, b: 255 },
  10000: { r: 207, g: 218, b: 255 },
  10100: { r: 207, g: 218, b: 255 },
  10200: { r: 206, g: 217, b: 255 },
  10300: { r: 205, g: 217, b: 255 },
  10400: { r: 204, g: 216, b: 255 },
  10500: { r: 204, g: 216, b: 255 },
  10600: { r: 203, g: 215, b: 255 },
  10700: { r: 202, g: 215, b: 255 },
  10800: { r: 202, g: 214, b: 255 },
  10900: { r: 201, g: 214, b: 255 },
  11000: { r: 200, g: 213, b: 255 },
  11100: { r: 200, g: 213, b: 255 },
  11200: { r: 199, g: 212, b: 255 },
  11300: { r: 198, g: 212, b: 255 },
  11400: { r: 198, g: 212, b: 255 },
  11500: { r: 197, g: 211, b: 255 },
  11600: { r: 197, g: 211, b: 255 },
  11700: { r: 197, g: 210, b: 255 },
  11800: { r: 196, g: 210, b: 255 },
  11900: { r: 195, g: 210, b: 255 },
  12000: { r: 195, g: 209, b: 255 },
};
